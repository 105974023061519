<template>
    <table v-show="!isInProgress" class="lists_table">
        <caption>利用者一覧</caption>
        <tr>
            <th>種別</th>
            <th>担当者名</th>
            <th>メールアドレス</th>
            <th>部署名</th>
            <th>電話番号</th>
            <th>登録日</th>
            <th>最終更新日</th>
            <th v-if="isEditOrConfirm">ロック解除</th>
        </tr>
        <tr
            v-for="item in users"
            :key="'user' + item.id"
            class="user-box"
        >
            <td>
                <span
                    v-if="item.is_organization_administrator"
                    class="admin"
                    :key="'organizationAdministrator' + item.id"
                    :ref="'organizationAdministrator' + item.id"
                >
                    管理者
                </span>
                <span
                    v-else
                    :key="'organizationUser' + item.id"
                    :ref="'organizationUser' + item.id"
                >
                    追加利用者
                </span>
            </td>
            <td>{{item.name}}</td>
            <td>{{item.email}}</td>
            <td>{{item.division}}</td>
            <td>{{item.tel}}</td>
            <td>{{date(item.created_at)}}</td>
            <td>{{date(item.updated_at)}}</td>
            <td v-if="isEditOrConfirm">
                <input
                    v-show="item.locked"
                    :disabled="isDisableCheckBox(item)"
                    type="checkbox"
                    :value="item.id"
                    :id="'isEditOrConfirm'+item.id"
                    v-model="idList"
                    class="el_checkbox_input"
                />
                <label 
                    :for="'isEditOrConfirm'+item.id" 
                    class="el_checkbox_label"
                >
                    <span v-if="isLocked(item)">ロック中</span>
                    <span v-if="isUnlocked(item)">ロック解除</span>
                </label>
            </td>
        </tr>
    </table>
</template>

<script>
/**
 * 事業者情報管理で使用する事業者所属ユーザ一覧
 * 
 * [算出プロパティ]
 * date...日付文字列を「YYYY/MM/DD」の形式に成形
 * isApproved...事業者が承認済か否か
 * isNotApproved...事業者が未承認か否か
 * isNotDeleted...事業者が削除済みでなければtrue
 * 
 * [イベント処理]
 * moveTo...エラーを削除し引数で指定したpathへ遷移
 * toggle...削除/承認取消ボタンの表示切替
 * toBrandEdit...現在の事業者を指定した状態でブランド名作成画面に遷移
 */
import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties } from '@/util'
import moment from 'moment'
import Progress from '@/mixins/progress.js'

export default {
    name:'AdmiistratorOrganizationUsersTable',
    mixins:[Progress],
    computed:{
        ...mapGetters({
            users:'admin/organization/users',
            data:'admin/organization/idList'
        }),
        ...mapComputedProperties([
            'idList'
        ]),
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        /**
         * 事業者情報編集ページでの表示か否か
         */
        isEditOrConfirm(){
            return this.$route.meta.type === 'edit' || this.$route.meta.type === 'confirm'
        },
        isDisableCheckBox(){
            return function(item){
                return !item.locked || this.$route.meta.type === 'confirm'
            }
        },
        isLocked(){
            return function(item){
                return item.locked && (this.$route.meta.type === 'edit' || this.idList.includes(item.id) === false)
            }
        },
        isUnlocked(){
            return function(item){
                return item.locked && this.$route.meta.type === 'confirm' && this.idList.includes(item.id)
            }
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        await this.getOrganizationUsers()
        this.finishProgress()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getOrganizationUsers:'admin/organization/getOrganizationUsers',
            update:'admin/organization/update'
        }),
    }
}
</script>

<style scoped>
.lists_table caption{
    font-weight: 600;
    text-align: left;
}
table.lists_table tr > th:last-of-type, table.lists_table tr > td:last-of-type{
    text-align: center;
}
</style>