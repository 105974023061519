<template>
    <default-template
        title="事業者情報管理"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者情報削除</h2>
            </div>
            <error-list />
            <administrator-organization-confirm />
            <administrator-organization-users-table />
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    @click="moveTo('/admin/organizations/' + organization.id)"
                    ref="jest-organization-back"
                    class="cancel"
                />
                <app-button
                    v-if="isNotApproved"
                    name="削除"
                    @click="deleteOrganization"
                    ref="jest-organization-delete"
                    class="delete"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P211 事業者情報管理(事業者詳細）
 *
 * [データ]
 * showDisableButtons...削除ボタン・承認取消ボタンがあるdivの表示/非表示
 * 
 * [算出プロパティ]
 * isHouse...事業者が住宅版事業者か否か
 * isNotApproved...事業者が未承認か否か
 * 
 * [イベント処理]
 * moveTo...エラーを削除し引数で指定したpathへ遷移
 */
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import ErrorList from '@/components/molecules/ErrorList'
import AdministratorOrganizationConfirm from '@/components/organisms/AdministratorOrganizationConfirm'
import AdministratorOrganizationUsersTable from '@/components/organisms/AdministratorOrganizationUsersTable'

export default {
    name:'OrganizationsDetail',
    mixins:[Progress],
    components:{
        ErrorList,
        AdministratorOrganizationConfirm,
        AdministratorOrganizationUsersTable,
    },
    data(){
        return {
            showDisableButtons:false
        }
    },
    computed:{
        ...mapGetters({
            organization:'admin/organization/organization',
        }),
        /**
         * 事業者が住宅版事業者か否か
         */
        isHouse(){
            return this.organization.type == 1
        },
        /**
         * 事業者が未承認か否か
         */
        isNotApproved(){
            return this.organization.status == 0
        },
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        await this.getOrganization()
        this.finishProgress()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getOrganization:'admin/organization/getOrganization',
            delete:'admin/organization/delete',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /**
         * エラーを削除し引数で指定したpathへ遷移
         */
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        deleteOrganization(){
            this.clearError()
            return this.delete()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        move(this.$router, '/admin/organizations')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
</style>