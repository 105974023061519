<template>
    <div
        class="organization-box bl_organizationBox"
        v-show="!isInProgress"
    >
        <div class="ly_form_box">
            <app-text
                name="name"
                v-model="name"
                label="事業者名"
                class="formrow wd_30em"
                ref="jest-organization-edit-name"
                v-if="isEdit"
            />
            <div class="formrow">
                <app-label
                    name="string_type"
                    label="種別"
                />
                <p ref="jest-organization-string_type">{{data.string_type}}</p>
            </div>
            <div
                class="formrow"
                v-if="!isEdit"
            >
                <app-label
                    name="name"
                    label="事業者名"
                />
                <p ref="jest-organization-name">{{data.name}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="string_status"
                    label="ステータス"
                />
                <p ref="jest-organization-string-status">{{data.string_status}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="address"
                    label="所在地"
                />
                <p ref="jest-organization-address">{{data.postcode}}  {{data.address}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="tel"
                    label="連絡先"
                />
                <p ref="jest-organization-tel">{{data.tel}}</p>
            </div>
            <div
                class="formrow"
                v-if="data.type == 1"
            >
                <app-label
                    name="belong_to"
                    label="所属団体"
                />
                <p ref="jest-organization-belong-to">{{data.string_belong_to}}</p>
            </div>
            <div
                v-if="showDeviceManager"
                key="deviceAuthority"
                class="formrow"
            >
                <app-label
                    name="deviceAuthority"
                    label="機器登録権限"
                />
                <p ref="jest-organization-device-authority">{{deviceAuthority}}</p>
            </div>
            <div
                v-if="isAbleToEditDeviceManager"
                key="deviceAuthorityForm"
                class="formrow"
            >
                <app-label
                    name="deviceAuthority"
                    label="機器登録権限"
                />
                <div class="uq_deviceAuthority">
                    <label
                        :class="{'checked':isUnCheckedAll}"
                        class="el_checkbox_label">
                        <input
                            type="checkbox"
                            :checked="isUnCheckedAll"
                            @click="unCheck"
                            class="el_checkbox_input"
                        />
                        なし
                    </label>
                    <label
                        :class="{'checked':cogeneration_manager}"
                        class="el_checkbox_label">
                        <input
                            type="checkbox"
                            v-model="cogeneration_manager"
                            class="el_checkbox_input"
                        />
                        コージェネレーション
                    </label>
                    <label
                        :class="{'checked':hybrid_water_heater_manager}"
                        class="el_checkbox_label">
                        <input
                            type="checkbox"
                            v-model="hybrid_water_heater_manager"
                            class="el_checkbox_input"
                        />
                        ハイブリッド給湯機</label>
                    <label
                        :class="{'checked':electric_heat_pump_manager}"
                        class="el_checkbox_label">
                        <input
                            type="checkbox"
                            v-model="electric_heat_pump_manager"
                            class="el_checkbox_input"
                        />
                        電気ヒートポンプ給湯機
                    </label>
                    <label
                        :class="{'checked':solar_system_manager}"
                        class="el_checkbox_label">
                        <input
                            type="checkbox"
                            v-model="solar_system_manager"
                            class="el_checkbox_input"
                        />
                        ソーラーシステム
                    </label>
                    <label
                        :class="{'checked':solar_water_heater_manager}"
                        class="el_checkbox_label">
                        <input
                            type="checkbox"
                            v-model="solar_water_heater_manager"
                            class="el_checkbox_input"
                        />
                        太陽熱温水器
                    </label>
                </div>
            </div>
            <div class="formrow">
                <app-label
                    name="created_at"
                    label="登録日"
                />
                <p ref="jest-organization-created-at">{{date(data.created_at)}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="approved_at"
                    label="承認日"
                />
                <p ref="jest-organization-approved-at">{{date(data.approved_at)}}</p>
            </div>
            <div class="formrow">
                <app-label
                    name="updated_at"
                    label="最終更新日"
                />
                <p ref="jest-organization-updated-at">{{date(data.updated_at)}}</p>
            </div>
        </div>
    </div>  
</template>

<script>
/**
 * 事業者情報管理で使用する事業者詳細
 *
 * [算出プロパティ]
 * isHouse...事業者が住宅版事業者か否か
 * deviceAuthority...機器登録権限に表示するテキスト
 * cogenerationManager...コジェネ機器の権限がある場合表示用テキストを返す
 * hybridWaterHeaterManager...ハイブリッド給湯機の権限がある場合表示用テキストを返す
 * date...日付文字列を「YYYY/MM/DD」の形式に成形
 */
import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties } from '@/util'
import moment from 'moment'
import Progress from '@/mixins/progress.js'

export default {
    name:'AdministratorOrganizationConfirm',
    mixins:[Progress],
    computed:{
        ...mapGetters({
            data:'admin/organization/organization',
        }),
        ...mapComputedProperties([
            'name',
            'cogeneration_manager',
            'hybrid_water_heater_manager',
            'electric_heat_pump_manager',
            'solar_system_manager',
            'solar_water_heater_manager',
        ]),
        /**
         * 事業者が住宅版事業者か否か
         */
        isHouse(){
            return this.data.type == 1
        },
        /**
         * 機器登録権限に表示するテキスト
         */
        deviceAuthority(){
            return this.cogenerationManager + this.hybridWaterHeaterManager + this.electricHeatPumpManager + this.solarSystemManager + this.solarWaterHeaterManager
        },
        /**
         * コジェネ機器の権限がある場合表示用テキストを返す
         */
        cogenerationManager(){
            return this.data.cogeneration_manager ? 'コージェネレーション' : ''
        },
        /**
         * ハイブリッド給湯機の権限がある場合表示用テキストを返す
         */
        hybridWaterHeaterManager(){
            const withAnyDevice = this.data.cogeneration_manager ? '・' : ''
            return this.data.hybrid_water_heater_manager ? withAnyDevice + 'ハイブリッド給湯機' : ''
        },
        /**
         * 電気ヒートポンプ給湯機の権限がある場合表示用テキストを返す
         */
        electricHeatPumpManager(){
            const withAnyDevice = this.data.cogeneration_manager || this.data.hybrid_water_heater_manager ? '・' : ''
            return this.data.electric_heat_pump_manager ? withAnyDevice + '電気ヒートポンプ給湯機' : ''
        },
        /**
         * ソーラーシステムの権限がある場合表示用テキストを返す
         */
        solarSystemManager(){
            const withAnyDevice = this.data.cogeneration_manager || this.data.hybrid_water_heater_manager
                || this.data.electric_heat_pump_manager
            const deviceConnector = withAnyDevice ? '・' : ''
            return this.data.solar_system_manager ? deviceConnector + 'ソーラーシステム' : ''
        },
        /**
         * 太陽熱温水器の権限がある場合表示用テキストを返す
         */
        solarWaterHeaterManager(){
            const withAnyDevice = this.data.cogeneration_manager || this.data.hybrid_water_heater_manager
                || this.data.electric_heat_pump_manager || this.data.solar_system_manager
            const deviceConnector = withAnyDevice ? '・' : ''
            return this.data.solar_water_heater_manager ? deviceConnector + '太陽熱温水器' : ''
        },
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        /**
         * 承認画面か否か
         */
        isEnable(){
            return this.$route.meta.type === 'enabled'
        },
        /**
         * 編集画面か否か
         */
        isEdit(){
            return this.$route.meta.type === 'edit'
        },
        /**
         * 機器登録権限を編集する画面か否か
         */
        isAbleToEditDeviceManager(){
            return (this.isEnable || this.isEdit) && this.isHouse
        },
        showDeviceManager(){
            return (this.isEnable || this.isEdit) === false && this.isHouse
        },
        isUnCheckedAll(){
            return this.cogeneration_manager === false && this.hybrid_water_heater_manager === false && this.electric_heat_pump_manager === false && this.solar_system_manager === false && this.solar_water_heater_manager === false
        }
    },
    methods:{
        ...mapActions({
            update:'admin/organization/updateOrganization',
        }),
        unCheck(){
            this.cogeneration_manager = false
            this.hybrid_water_heater_manager = false
            this.electric_heat_pump_manager = false
            this.solar_system_manager = false
            this.solar_water_heater_manager = false
        },
    }
}
</script>

<style scoped>
.bl_container .bl_organizationBox {
    grid-template-columns: 1fr;
}
.ly_form_box{
    border:none;
    width: 100%;
    min-width: 600px;
}
.organization-box div.formrow {
    grid-template-columns: 8em 1fr;
    width: 100%;
}
.uq_deviceAuthority{
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
}
.uq_deviceAuthority>label{
    display: inline-block;
    margin: 0 0 6px;
}
.el_checkbox_label.checked:after{
    top: 5px;
}
.ly_form_box .formrow p{
    margin: 0.25rem 0;
    font-size: 14px;
    line-height: 16px;
}
.wd_30em >>> input[type="text"]{
    width: 30em;
}
</style>