<template>
    <default-template
        title="事業者情報管理"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者情報</h2>
            </div>
            
            <error-list class="notice" />
            <app-modal
                v-if="showPasswordModal"
                @close="togglePasswordModal"
            >
                <p class="bl_modal_content_p__center">
                    この事業者のファイルアップロードパスワードは<br>
                    <span class="uq_wd100_center">{{organization.zip_password}}</span>
                    です。
                </p>
            </app-modal>
            <administrator-organization-confirm />
            <administrator-organization-users-table />
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    @click="moveTo('/admin/organizations')"
                    ref="jest-organization-back"
                    class="cancel"
                />
                <app-button
                    name="事業者情報変更"
                    @click="toEdit"
                    ref="jest-organization-edit"
                />
                <app-button
                    v-if="isDeviceManager"
                    name="ブランド事業者登録"
                    @click="toBrandEdit"
                    ref="jest-organization-brand-create"
                />
                <app-button
                    v-if="isNotApproved"
                    name="承認"
                    @click="moveTo('/admin/organizations/' + organization.id + '/approved/enabled')"
                    ref="jest-organization-approved-enabled"
                />
                <div
                    v-show="showDisableButtons"
                    class="uq_foldedContainer">
                    <app-button
                        v-if="isNotApproved"
                        name="削除"
                        @click="moveTo('/admin/organizations/' + organization.id + '/delete')"
                        ref="jest-organization-delete"
                        class="delete"
                    />
                    <app-button
                        v-if="isApproved"
                        name="承認取消"
                        @click="moveTo('/admin/organizations/' + organization.id + '/approved/disabled')"
                        ref="jest-organization-approved-disabled"
                        class="delete"
                    />
                    <app-button
                        v-if="isHouse"
                        name="ファイルアップロードパスワード確認"
                        @click="togglePasswordModal"
                        ref="jest-organization-show-password-modal"
                        class="delete"
                    />
                </div>
                <app-button
                    v-if="isNotDeleted"
                    name="…"
                    @click="toggle"
                    ref="jest-organization-toggle"
                    class="uq_togglebutton"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P211 事業者情報管理(事業者詳細）
 *
 * [データ]
 * showDisableButtons...削除ボタン・承認取消ボタンがあるdivの表示/非表示
 * 
 * [算出プロパティ]
 * isHouse...事業者が住宅版事業者か否か
 * isApproved...事業者が承認済か否か
 * isNotApproved...事業者が未承認か否か
 * isNotDeleted...事業者が削除済みでなければtrue
 * 
 * [イベント処理]
 * moveTo...エラーを削除し引数で指定したpathへ遷移
 * toggle...削除/承認取消ボタンの表示切替
 * toBrandEdit...現在の事業者を指定した状態でブランド名作成画面に遷移
 */
import { mapActions, mapGetters } from 'vuex'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import AppModal from '@/components/molecules/AppModal'
import ErrorList from '@/components/molecules/ErrorList'
import AdministratorOrganizationConfirm from '@/components/organisms/AdministratorOrganizationConfirm'
import AdministratorOrganizationUsersTable from '@/components/organisms/AdministratorOrganizationUsersTable'

export default {
    name:'OrganizationsDetail',
    mixins:[Progress],
    components:{
        AppModal,
        ErrorList,
        AdministratorOrganizationConfirm,
        AdministratorOrganizationUsersTable,
    },
    data(){
        return {
            showDisableButtons:false,
            showPasswordModal:false,
        }
    },
    computed:{
        ...mapGetters({
            organization:'admin/organization/organization',
        }),
        /**
         * 事業者が住宅版事業者か否か
         */
        isHouse(){
            return this.organization.type == 1
        },
        /**
         * 事業者が承認済か否か
         */
        isApproved(){
            return this.organization.status == 1
        },
        /**
         * 事業者が未承認か否か
         */
        isNotApproved(){
            return this.organization.status == 0
        },
        /**
         * 事業者が削除済みでなければtrue
         */
        isNotDeleted(){
            return this.organization.status != 9
        },
        isDeviceManager(){
            return this.organization.cogeneration_manager || this.organization.hybrid_water_heater_manager
                || this.organization.electric_heat_pump_manager || this.organization.solar_system_manager
                || this.organization.solar_water_heater_manager
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        await this.getOrganization()
        this.finishProgress()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getOrganization:'admin/organization/getOrganization',
            update:'admin/brandNames/update',
            updateOrganization:'admin/organization/update',
            clearError:'error/clear',
        }),
        /**
         * エラーを削除し引数で指定したpathへ遷移
         */
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        toggle(){
            this.showDisableButtons = !this.showDisableButtons
        },
        togglePasswordModal(){
            this.showPasswordModal = !this.showPasswordModal
            if (this.showPasswordModal) {
                // 下層レイヤのスクロール位置を固定する
                disableBodyScroll(document.querySelector('#app'))
            } else {
                // 下層レイヤのスクロール位置固定を解除
                clearAllBodyScrollLocks()
            }
        },
        async toBrandEdit(){
            await this.update({brandName:{
                name:null,
                organization_id:this.organization.id,
                note:null,
            }})
            this.moveTo('/admin/brand_names/create')
        },
        async toEdit(){
            await this.updateOrganization({idList:[]})
            this.moveTo('/admin/organizations/' + this.organization.id + '/edit')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
    max-width: 100%;
}
.lists_table{
    width: 100%;
}
.bl_contents_wrapper >>> .ly_form_box {
    width: 100%;
}
.uq_wd_300px{
    width: 300px;
    min-width: 300px;
}
.ly_buttonBox{
    position: relative;
}

.bl_modal_content_p__center{
    text-align: center;
}
.uq_wd100_center{
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 20px;
}
</style>