<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
    
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者一覧</h2>
            </div>
            <div class="bl_searchBox_outer">
                <div class="bl_searchBox ly_searchBox">
                    <div class="uq_flexcolumn">
                        <div class="bl_input_container uq_grid1_label">
                            <app-label
                                name="searchType"
                                label="種別"
                                class="rowlabel grid1"
                            />
                            <app-radio
                                v-model="searchType"
                                value="house"
                                label="住宅版"
                            />
                            <app-radio
                                v-model="searchType"
                                value="building"
                                label="非住宅版"
                            />
                        </div>
                        <div class="bl_input_container uq_grid1_label">
                            <app-text
                                name="keyword"
                                v-model="keyword"
                                label="事業者名"
                                ref="jest-organization-keyword"
                                class="formrow"
                            />
                        </div>
                        <div class="bl_input_container uq_grid1_label">
                            <app-label
                                name="searchStatus"
                                label="ステータス"
                                class="rowlabel grid1 uq_states"
                            />
                            <app-select
                                v-model="searchStatus"
                                :label="'未選択'"
                                :vmodel-label="master.organizationsStatus[searchStatus]"
                                ref="jest-organization-status"
                            >
                                <app-select-option
                                    :value="null"
                                    v-model="searchStatus"
                                >
                                    未選択
                                </app-select-option>
                                <app-select-option
                                    v-for="(item, index) in master.organizationsStatus"
                                    :key="'organizationsStatus' + index"
                                    :value="index"
                                    v-model="searchStatus"
                                >
                                    {{item}}
                                </app-select-option>
                            </app-select>
                        </div>
                    </div>
                    
                    <div class="uq_flexcolumn">
                        <div class="bl_input_container uq_date">
                            <app-label
                                label="期間の指定"
                                class="rowlabel grid1"
                            />
                            <app-radio
                                v-model="dateType"
                                value=""
                                label="指定しない"
                            />
                            <app-radio
                                v-model="dateType"
                                value="created_at"
                                label="登録日"
                            />
                            <app-radio
                                v-model="dateType"
                                value="updated_at"
                                label="更新日"
                            />
                            <app-radio
                                v-model="dateType"
                                value="approved_at"
                                label="承認日"
                            />
                        </div>
                        <div 
                            class="bl_input_container uq_date"
                        >
                            <label
                                class="rowlabel grid1 el_label"
                            >
                                <span v-if="dateType == 'created_at'">登録日</span>
                                <span v-else-if="dateType == 'updated_at'">更新日</span>
                                <span v-else-if="dateType == 'approved_at'">承認日</span>
                            </label>
                            <app-text-box
                                name="startDate"
                                v-model="startDate"
                                label=""
                                class="formrow"
                                type="date"
                                ref="jest-orgnaization-start-date"
                                :disabled="dateType == ''"
                            />
                            ～
                            <app-text-box
                                name="finishDate"
                                v-model="finishDate"
                                label=""
                                class="formrow"
                                type="date"
                                ref="jest-orgnaization-finish-date"
                                :disabled="dateType == ''"
                            />
                        </div>
                        <app-button
                            name="検索"
                            @click="search"
                            ref="jest-organization-search"
                            class="inline wd_100px uq_endbutton"
                        />
                    </div>
                </div>
            </div>
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table
                v-if="organizations.length !== 0"
                key="resultTable"
                ref="jest-result-table"
                class="lists_table"
            >
                <tr>
                    <th>種別</th>
                    <th>事業者名</th>
                    <th>ステータス</th>
                    <th>登録日</th>
                    <th>更新日</th>
                    <th>承認日</th>
                    <th></th>
                </tr>
                <tr
                    v-for="item in organizations"
                    :key="'organizations' + item.id"
                    :ref="'jest-organizations' + item.id"
                >
                    <td>{{formattedType(item.string_type)}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.string_status}}</td>
                    <td>{{date(item.created_at)}}</td>
                    <td>{{date(item.updated_at)}}</td>
                    <td>{{date(item.approved_at)}}</td>
                    <td>
                        <app-button
                            name="詳細"
                            @click="moveTo(item)"
                            :ref="'jest-organization-detail' + item.id"
                            class="inline wd_80px"
                        />
                    </td>
                </tr>
            </table>
            <list-pager
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
            <p
                v-if="organizations.length === 0"
                key="resultParagraph"
                ref="jest-result-paragraph"
            >
                検索結果はありません
            </p>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { move, mapComputedProperties, pageContentCount } from '@/util'
import master from '@/mixins/master'
import ListPager from '@/components/molecules/ListPager'

export default {
    name: 'AdminOrganizationList',
    components:{
        ListPager,
    },
    mixins:[master],
    computed:{
        ...mapGetters({
            organizations:'admin/organization/organizations',
            data:'admin/organization/getSearchParameters'
        }),
        ...mapComputedProperties([
            'total',
            'page',
            'searchType',
            'keyword',
            'searchStatus',
            'dateType',
            'startDate',
            'finishDate',
        ]),
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        /**
         * item.string_typeの「XX版事業者」を「XX版」の形式に成形
         */
        formattedType(){
            return function (stringType) {
                return stringType ? stringType.replace('事業者', '') : null
            }
        },
        /**
         * リストの件数の合計から算出されるリストのページ数を返す
         */
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    watch:{
        dateType(value){
            if(value === '') {
                this.startDate = null
                this.finishDate = null
            }
        }
    },
    async mounted(){
        this.getOrganizations()
    },
    methods:{
        ...mapActions({
            getOrganizations:'admin/organization/getOrganizations',
            update:'admin/organization/update',
            clearError:'error/clear'
        }),
        /**
         * 事業者リストのページの切り替え
         */
        async pageChange(page){
            const loader = this.$loading.show()
            await this.update({page})
            await this.getOrganizations()
            loader.hide()
        },
        /**
         * 事業者リストのページの切り替え
         */
        async search(){
            const loader = this.$loading.show()
            await this.update({page:1})
            await this.getOrganizations()
            loader.hide()
        },
        async moveTo(item){
            this.clearError()
            await this.update({organization:item})
            move(this.$router, '/admin/organizations/' + item.id)
        }
    }
}
</script>

<style scoped>
.bl_searchBox{
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.uq_flexcolumn{
    display: flex;
    flex-direction: column;
}
.uq_flexcolumn:first-child{
    margin-right: 16px;
}
.uq_flexcolumn >>> .select_btn{
    min-width: 148px;
}
.uq_flexcolumn >>> .select_wrapper label{
    width: 100%;
}
.uq_flexcolumn >>> label.grid1{
    width: 7.5em;
    font-weight: 600;
    font-size: 12px;
}
.uq_grid1_label >>> .el_label.grid1{
    width: 5em;
}
.uq_flexcolumn >>> .radio>label{
    font-size: 14px;
}
.uq_flexcolumn >>> p{
    font-weight: 600;
    font-size: 12px;
}
.uq_flexcolumn .uq_date .el_label{
    width: 5em;
}
button.el_button.uq_endbutton{
    margin: 12px 0 0 auto;
}
.bl_searchBox>.bl_input_container:not(:last-of-type){
    margin-right: 16px;
    margin-bottom: 0;
}
.bl_searchBox .bl_input_container_column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.select_wrapper >>> div.select_btn{
    width: 12em;
}
.uq_include_expired{
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}
.lists_table{
    width: 100%;
}
.uq_states{
    width: 12em;
}
</style>