<template>
    <default-template
        title="事業者情報編集確認"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者情報編集確認</h2>
            </div>
            <error-list class="notice" />
            <administrator-organization-confirm />
            <administrator-organization-users-table />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/admin/organizations/' + organization.id + '/edit')"
                    ref="jest-organization-back"
                    class="cancel"
                />
                <app-button
                    name="変更確定"
                    @click="edit"
                    ref="jest-organization-edit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P211 事業者情報管理(事業者詳細）
 *
 * [データ]
 * showDisableButtons...削除ボタン・承認取消ボタンがあるdivの表示/非表示
 * 
 * [算出プロパティ]
 * isHouse...事業者が住宅版事業者か否か
 * 
 * [イベント処理]
 * moveTo...エラーを削除し引数で指定したpathへ遷移
 */
import { mapActions, mapGetters } from 'vuex'
import { mapComputedProperties, move } from '@/util'
import Progress from '@/mixins/progress.js'
import ErrorList from '@/components/molecules/ErrorList'
import AdministratorOrganizationConfirm from '@/components/organisms/AdministratorOrganizationConfirm'
import AdministratorOrganizationUsersTable from '@/components/organisms/AdministratorOrganizationUsersTable'

export default {
    name:'OrganizationsDetail',
    mixins:[Progress],
    components:{
        ErrorList,
        AdministratorOrganizationConfirm,
        AdministratorOrganizationUsersTable,
    },
    computed:{
        ...mapGetters({
            organization:'admin/organization/organization',
            data:'admin/organization/idList'
        }),
        ...mapComputedProperties([
            'idList'
        ]),
        /**
         * 事業者が住宅版事業者か否か
         */
        isHouse(){
            return this.organization.type == 1
        },
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            editOrganization:'admin/organization/edit',
            unlockUsers:'admin/organization/unlockUsers'
        }),
        /**
         * エラーを削除し引数で指定したpathへ遷移
         */
        moveTo(path){
            this.clearError()
            move(this.$router, path)
        },
        async edit(){
            this.clearError()
            await Promise.all([
                this.editOrganization(),
                this.unlockUsers()
            ])
                .then(response => {
                    const noErrorForEdit = response[0].status === 200
                    const noErrorForUnlock = response[1].status === 200
                    if (noErrorForEdit && noErrorForUnlock) {
                        this.updateError(response[0])
                        if (this.idList.length !== 0){
                            this.updateError(response[1])
                        }
                        move(this.$router, '/admin/organizations/' + this.organization.id)
                    } else {
                        if (!noErrorForEdit) {
                            this.updateError(response[0])
                        }
                        if (!noErrorForUnlock) {
                            this.updateError(response[1])
                        }
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
    max-width: 100%;
}
.lists_table{
    width: 100%;
}
.form_box {
    width: 100%;
}
</style>