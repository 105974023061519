<template>
    <default-template
        title="事業者情報管理"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="isHouse"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">事業者承認<span v-if="isDisable">取消</span></h2>
            </div>
            <error-list />
            <p
                v-if="isEnable"
                key="enableConfirm"
            >
                以下の事業者を承認します。よろしいですか？<br /><span v-if="isHouse">機器登録権限を付与する場合は、以下で機器登録権限を設定してください。</span>
            </p>
            <p
                v-if="isDisable"
                key="disableConfirm"
            >
                以下の事業者の承認を取消します。よろしいですか？
            </p>
            <administrator-organization-confirm />
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    @click="back"
                    ref="jest-organization-back"
                    class="cancel"
                />
                <app-button
                    v-if="isEnable"
                    key="isEnable"
                    name="承認"
                    @click="enabled"
                    ref="jest-organization-approved-enabled"
                />
                <app-button
                    v-if="isDisable"
                    key="isDisable"
                    name="承認取消"
                    @click="disabled"
                    ref="jest-organization-approved-disabled"
                    class="delete"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P212 管理者：事業者承認, P213 管理者：事業者承認取消
 *
 * [算出プロパティ]
 * isHouse...事業者が住宅版事業者か否か
 * isDisable...事業者が承認済か否か
 * isEnable...事業者が未承認か否か
 * isNotDeleted...事業者が削除済みでなければtrue
 * 
 * [イベント処理]
 * back...事業者詳細に戻る
 */
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import ErrorList from '@/components/molecules/ErrorList'
import AdministratorOrganizationConfirm from '@/components/organisms/AdministratorOrganizationConfirm'

export default {
    name:'OrganizationsDetail',
    components:{
        ErrorList,
        AdministratorOrganizationConfirm,
    },
    mixins:[Progress],
    computed:{
        ...mapGetters({
            organization:'admin/organization/organization',
        }),
        /**
         * 事業者が住宅版事業者か否か
         */
        isHouse(){
            return this.organization.type == 1
        },
        /**
         * 事業者が承認済か否か
         */
        isDisable(){
            return this.organization.status == 1
        },
        /**
         * 事業者が未承認か否か
         */
        isEnable(){
            return this.organization.status == 0
        },
        /**
         * 事業者が削除済みでなければtrue
         */
        isNotDeleted(){
            return this.organization.status != 9
        },
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        await this.getOrganization()
        this.finishProgress()
        loader.hide()
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            getOrganization:'admin/organization/getOrganization',
            enabledOrganization:'admin/organization/enabled',
            disabledOrganization:'admin/organization/disabled',
        }),
        back(){
            move(this.$router, '/admin/organizations/' + this.organization.id)
        },
        enabled(){
            this.clearError()
            return this.enabledOrganization()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        disabled(){
            this.clearError()
            return this.disabledOrganization()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.lists_table{
    width: 100%;
}
.bl_contents_wrapper >>> .ly_form_box {
    width: 100%;
}
</style>